import { Button } from "@material-ui/core";
import React from "react";
import Style from "./Style.module.scss";
import Send from "../../Images/send.svg";

const ShareButton = ({ name, state, res_code }) => {
  const handleShare = () => {
    console.log("SHARE", res_code);
    if (navigator.share) {
      navigator
        .share({
          title: "Covidmealsforindia.com",
          text: `Hey! \n \n *${name}* is live on *Covid Meals For India* and providing food services in *${state}*.\n \n\n Click below to view our menu 👇🏻 https://covidmealsforindia.com/${res_code} \n\n 🇮🇳 *Covid Meals For India* is a public initiative which is helping people who are in need, connect with food providers in your locality.\n \n 🔎 1. Go to covidmealsforindia.com\n 📍 2. Search for your locality \n 🍲 3. Place order on WhatsApp to the service provider.\n \n 🚀 *Share this with people who need & become become a part of this movement by helping the needy finding food providers across India*`,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong", error);
        });
    }
  };

  return (
    <Button className={Style.share} onClick={handleShare}>
      <img src={Send} height="20" weight="20" alt="send" />
      Share your listing
    </Button>
  );
};

export default ShareButton;
