import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeDetails, setImageDelete } from "../../Actions/Restaurant";
import Style from "../Register/Style.module.scss";
import Menu from "../../Images/menu.svg";
import Gallery from "../../Images/gallery.svg";
import ins from "../../Core/Api";
import { useHistory } from "react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Logo from "../../Images/Logo.svg";
import { states } from "../../Core/State";
import Snackbar from "../../Components/Snackbar";
import { ResetError } from "../../Reducers/Restaurant";

export default function Register() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.restaurant);
  const history = useHistory();
  const [name, setName] = useState(data.restaurant_name);
  const [email, setEmail] = useState(data?.tmp_merchant?.email);
  const [address1, setAddress1] = useState(data?.tmp_location?.address_1);
  const [address2, setAddress2] = useState(data?.tmp_location?.address_2);
  const [city, setCity] = useState(data?.tmp_location?.city);
  const [state, setState] = useState(data?.tmp_location?.state);
  const [zip, setZip] = useState(data?.tmp_location?.zip_code);
  const [number, setNumber] = useState(data.phone_numbers[0]);
  const [fname, setFname] = useState(data?.tmp_merchant?.fname);
  const [altContact, setAltContact] = useState(data.phone_numbers[1] || "");
  const [link, setLink] = useState(data.website);
  const [describe, setDescribe] = useState(data.description);
  const temp = Object.keys(data.service_days)
    .slice(0 - 1)
    .map((i) => data.service_days[i]?.open_at);
  const [startTime, setStartTime] = useState(temp);
  const temp2 = Object.keys(data.service_days)
    .slice(0 - 1)
    .map((i) => data.service_days[i]?.close_at);
  const [endTime, setEndTime] = useState(temp2);
  const [services, setServices] = useState({
    Direct: data?.delivery_mode?.Direct || true,
    "3rd Party": data?.delivery_mode[`3rd Party`],
    "Via Customer Only": data?.delivery_mode[`Via Customer Only`],
  });
  const [timing, setTiming] = useState({});

  console.log("Data", data);
  const [modes, setModes] = useState({
    Direct: false,
    Swiggy: false,
    Zomato: false,
  });

  const [days, setDays] = useState({
    Mon: data.service_days.Mon ? true : false,
    Tue: data.service_days.Tue ? true : false,
    Wed: data.service_days.Wed ? true : false,
    Thu: data.service_days.Thu ? true : false,
    Fri: data.service_days.Fri ? true : false,
    Sat: data.service_days.Sat ? true : false,
    Sun: data.service_days.Sun ? true : false,
  });

  const [charity, setCharity] = useState(data.is_charity);
  const [hideaddress1, setHideadress1] = useState(
    data.hide_my_location ? true : false
  );
  const [diet, setDiet] = useState(data.is_covid_special ? "Yes" : "No");
  const [menu, setMenu] = useState(data.menu_images);
  const [restaurant_image, setRestaurant_image] = useState(
    data.restaurant_image
  );
  const [menuLoad, setMenuLoad] = useState();
  const [restaurantLoad, setRestaurantLoad] = useState();
  const [load, setLoad] = useState(false);
  const { snack } = useSelector((state) => state.restaurant);
  const [business_Type, setBusinessType] = useState(data.service_type);
  const [validationError, setValidationError] = useState({
    address1: false,
    city: false,
    fname: false,
    service: false,
    pincode: false,
  });
  window.onbeforeunload = function () {
    console.log("hit");
    dispatch(ResetError());
  };
  const handleMenu = async (e) => {
    setMenuLoad(true);
    const files = e.target.files;
    const formData = new FormData();
    formData.append("image", files[0]);
    const instance = await ins();
    instance
      .post("merchant/image/upload", formData)
      .then((response) => {
        setMenu([...menu, response.data.data.url]);
      })
      .finally(() => {
        setMenuLoad(false);
      });
  };

  const handleRestaurant = async (e) => {
    setRestaurantLoad(true);
    const files = e.target.files;
    const formData = new FormData();
    formData.append("image", files[0]);
    const instance = await ins();
    instance
      .post("merchant/image/upload", formData)
      .then((response) => {
        setRestaurant_image(response.data.data.url);
      })
      .finally(() => {
        setRestaurantLoad(false);
      });
  };

  const handleRestaurantDelete = () => {
    dispatch(setImageDelete(restaurant_image));
    setRestaurant_image("");
  };

  const handleMenuDelete = (url) => {
    dispatch(setImageDelete(url));
    const temp = [...menu];
    const result = temp.filter((i) => i !== url);
    setMenu(result);
  };

  const handleServices = (e) => {
    setServices({
      ...services,
      [e.target.name]: e.target.checked,
    });
  };

  const handleModes = (e) => {
    setModes({
      ...modes,
      [e.target.name]: e.target.checked,
    });
  };

  const handleRadio = (e) => {
    setDiet(e.target.value);
  };

  const overrideDays = (startingTime, endingTime) => {
    const tempDay = {
      ...days,
    };
    let temp = {};
    Object.keys(tempDay).map((i) => {
      if (tempDay[i] === false) return;
      temp[i] = {
        open_at: startingTime,
        close_at: endingTime,
      };
    });
    console.log("Temp", temp);
    setTiming({
      // ...timing,
      ...temp,
    });
  };

  const handleStartTime = (e) => {
    overrideDays(e.target.value, endTime);
    setStartTime(e.target.value);
  };

  const handleEndTime = (e) => {
    overrideDays(startTime, e.target.value);
    setEndTime(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      restaurant_name: name,
      email,
      merchant_name: fname,
      phone_number: altContact,
      website: link,
      is_pickup: false,
      is_delivery: true,
      is_charity: charity,
      is_covid_special: diet,
      menu_images: menu,
      restaurant_image,
      description: describe,
      delivery_mode: services,
      service_type: business_Type,
      hide_my_location: hideaddress1,
      location: {
        address_1: address1,
        address_2: address2,
        zip_code: zip,
        state: state,
        city: city,
      },
      timing: timing,
    };

    const temp = {
      address1: false,
      city: false,
      fname: false,
      service: false,
      pincode: false,
    };

    if (!address1) {
      document?.getElementById("address1").scrollIntoView();
      setValidationError({
        ...temp,
        ...{ address1: true },
      });
    } else if (!city) {
      document?.getElementById("city").scrollIntoView();
      setValidationError({
        ...temp,
        ...{ city: true },
      });
    } else if (
      !(
        services?.Direct ||
        services["3rd Party"] ||
        services["Via Customer Only"]
      )
    ) {
      document?.getElementById("service").scrollIntoView();
      setValidationError({
        ...temp,
        ...{ service: true },
      });
      console.log("selecet");
    } else if (!fname) {
      document?.getElementById("fname").scrollIntoView();
      setValidationError({
        ...temp,
        ...{ fname: true },
      });
    } else if (!zip) {
      document?.getElementById("pincode").scrollIntoView();
      setValidationError({
        ...temp,
        ...{ pincode: true },
      });
    } else {
      setLoad(true);
      dispatch(changeDetails(data, history, setLoad));
    }
  };

  console.log(validationError);
  const handleSchedule = (e) => {
    setDays({
      ...days,
      [e.target.name]: e.target.checked,
    });
    const tempDay = {
      ...days,
      [e.target.name]: e.target.checked,
    };
    let temp = {};
    Object.keys(tempDay).map((i) => {
      if (tempDay[i] === false) return;
      temp[i] = {
        open_at: startTime,
        close_at: endTime,
      };
    });
    console.log("Temp", temp);
    setTiming({
      // ...timing,
      ...temp,
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const isEnabled = true;

  return (
    <form className={Style.container} onSubmit={handleSubmit}>
      <div
        className={Style.header}
        style={{ flexDirection: "column", padding: "0 20px" }}
      >
        <IconButton style={{ alignSelf: "flex-start", color: "white" }}>
          <ArrowBackIosIcon onClick={handleBack} />
        </IconButton>
        <div className={Style.imgContainer}>
          <img src={Logo} alt="logo" height="80" width="80" />
        </div>
        <h1>Edit Profile</h1>
      </div>
      <div className={Style.content}>
        <div className={Style.description}>
          <p> Update your profile</p>
        </div>
        <div className={Style.inputContainer}>
          <div className={Style.menuImages}>
            <h1>
              Upload Menu Images{" "}
              <span style={{ color: "red" }}>(2MB Max) </span>
            </h1>
            <div className={Style.menuImagesContainer}>
              {menu.map((img, index) => (
                <div
                  style={{
                    position: "relative",
                  }}
                  onClick={() => handleMenuDelete(img)}
                >
                  <DeleteOutlineOutlinedIcon
                    style={{
                      position: "absolute",
                      top: 44,
                      left: 35,
                      color: "#c4c4c4",
                      zIndex: 9999,
                    }}
                  />
                  <img
                    src={img}
                    alt="menu-uploaded"
                    key={index}
                    style={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      width: 95,
                      height: 95,
                      border: "1px solid #888888",
                      borderRadius: 8,
                      marginTop: 10,
                      marginRight: 10,
                    }}
                  />
                </div>
              ))}
              <label>
                {menuLoad ? (
                  <CircularProgress />
                ) : (
                  <img src={Menu} alt="menu" />
                )}
                <input type="file" onChange={handleMenu} />
              </label>
            </div>
          </div>
          <div className={Style.menuImages}>
            <h1>
              Upload Profile Pic{" "}
              <span style={{ color: "red" }}>(2MB Max) </span>
            </h1>
            <div className={Style.menuImagesContainer}>
              {restaurant_image.length > 0 ? (
                <div
                  style={{
                    position: "relative",
                  }}
                  onClick={handleRestaurantDelete}
                >
                  <DeleteOutlineOutlinedIcon
                    style={{
                      position: "absolute",
                      top: 44,
                      left: 35,
                      color: "#c4c4c4",
                      zIndex: 9999,
                    }}
                  />

                  <img
                    src={restaurant_image}
                    alt="menu-uploaded"
                    style={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      width: 95,
                      height: 95,
                      border: "1px solid #888888",
                      borderRadius: 8,
                      marginTop: 10,
                    }}
                  />
                </div>
              ) : (
                <label>
                  {restaurantLoad ? (
                    <CircularProgress />
                  ) : (
                    <img src={Gallery} alt="menu" />
                  )}
                  <input type="file" onChange={handleRestaurant} />
                </label>
              )}
            </div>
          </div>
          <h2>Business Details</h2>
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: "15px" }}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Business Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={business_Type}
              onChange={(e) => setBusinessType(e.target.value)}
              label="Business Type"
              required
            >
              <MenuItem value={"Home Chef"}>{"Home Chef"}</MenuItem>
              <MenuItem value={"Restaurant"}>{"Restaurant"}</MenuItem>
              <MenuItem value={"Cloud Kitchen"}>{"Cloud Kitchen"}</MenuItem>
              <MenuItem value={"Tiffin Service"}>{"Tiffin Service"}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Business Name"
            value={name}
            variant="outlined"
            disabled
          />
          <TextField
            type="email"
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Business Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            required
          />

          <div className={Style.checkboxGroup}>
            <h1>Services Active</h1>
            <FormGroup
              error={validationError.service}
              id="service"
              aria-label="position"
              row
            >
              <FormControlLabel
                name="Direct"
                checked={services.Direct}
                onChange={handleServices}
                control={<Checkbox color="primary" />}
                label="Direct"
              />
              <FormControlLabel
                name="3rd Party"
                checked={services["3rd Party"]}
                onChange={handleServices}
                control={<Checkbox color="primary" />}
                label="3rd Party"
              />
              <FormControlLabel
                name="Via Customer Only"
                checked={services["Via Customer Only"]}
                onChange={handleServices}
                control={<Checkbox color="primary" />}
                label="Via Customer Only"
              />
              <FormHelperText style={{ color: "red" }}>
                {validationError.service ? "Please Select Service Type" : ""}
              </FormHelperText>
            </FormGroup>
          </div>

          <TextField
            size="medium"
            fullWidth
            error={validationError.address1}
            style={{ marginTop: "15px" }}
            label="Address1 (Building/House No.) *"
            onChange={(e) => setAddress1(e.target.value)}
            value={address1}
            helperText={
              validationError.address1
                ? "Please Enter Address1 (Building/House No.)"
                : ""
            }
            id="address1"
            variant="outlined"
          />
          <div>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                checked={hideaddress1}
                onChange={(e) => setHideadress1(e.target.checked)}
                control={<Checkbox color="primary" />}
                label="Hide building/house no. from public visibility"
              />
            </FormGroup>
          </div>
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Address2 (Area/Locality)"
            onChange={(e) => setAddress2(e.target.value)}
            value={address2}
            variant="outlined"
          />
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="City *"
            error={validationError.city}
            onChange={(e) => setCity(e.target.value)}
            value={city}
            variant="outlined"
            id="city"
            helperText={validationError.city ? "Please Enter City Name" : ""}
          />
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: "15px" }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              State *
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state}
              onChange={(e) => setState(e.target.value)}
              label="State *"
            >
              {states.map((state, index) => (
                <MenuItem value={state} key={index}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            type="number"
            size="medium"
            fullWidth
            error={validationError.pincode}
            style={{ marginTop: "15px" }}
            label="Zip code *"
            onChange={(e) => setZip(e.target.value)}
            value={zip}
            id="pincode"
            helperText={validationError.pincode ? "Please Enter Zip Code" : ""}
            variant="outlined"
          />
          <h2>Personal Information</h2>
          <TextField
            type="number"
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Mobile Number"
            onChange={(e) => setNumber(e.target.value)}
            value={number}
            variant="outlined"
            disabled
          />
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Full Name *"
            onChange={(e) => setFname(e.target.value)}
            value={fname}
            error={validationError.fname}
            variant="outlined"
            id="fname"
            helperText={validationError.fname ? "Please Enter Full Name" : ""}
          />
          <TextField
            type="number"
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Alternative Contact Number (Optional)"
            onChange={(e) => setAltContact(e.target.value)}
            value={altContact}
            variant="outlined"
          />
          <h2>Scheduling</h2>
          <div className={Style.scheduling}>
            <TextField
              size="medium"
              id="time"
              label="Start Time"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              value={startTime}
              onChange={handleStartTime}
              variant="outlined"
            />
            <TextField
              size="medium"
              id="time"
              style={{ marginLeft: "20px" }}
              label="End Time"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              value={endTime}
              onChange={handleEndTime}
              inputProps={{
                step: 300, // 5 min
              }}
              variant="outlined"
            />
          </div>
          <div className={Style.schedulingbtn}>
            <FormGroup aria-label="position" row>
              {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                (data, index) => (
                  <FormControlLabel
                    key={index}
                    value={data}
                    name={data}
                    onChange={handleSchedule}
                    checked={days[data]}
                    control={<Checkbox color="primary" />}
                    label={data}
                    labelPlacement="right"
                  />
                )
              )}
            </FormGroup>
          </div>
          <h2>Other Details</h2>
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Website link(social media link or menu link)"
            onChange={(e) => setLink(e.target.value)}
            value={link}
            variant="outlined"
          />

          <div className={Style.radioGroup}>
            <h1>Covid Diet Food</h1>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={diet}
              onChange={handleRadio}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </div>

          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Describe meal in max 120 charachters"
            onChange={(e) => setDescribe(e.target.value)}
            value={describe}
            variant="outlined"
            multiline
          />

          <div className={Style.checkboxGroup}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                checked={charity}
                onChange={(e) => setCharity(e.target.checked)}
                control={<Checkbox color="primary" />}
                label="Charity(Click this if you are providing food for free)"
              />
            </FormGroup>
          </div>
        </div>
        <div className={Style.btnContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isEnabled ? false : true}
          >
            {load ? <CircularProgress style={{ color: "white" }} /> : "Edit"}
          </Button>
        </div>
      </div>
      {snack ? (
        <Snackbar key={snack.date} status={snack.status} color={snack.color} />
      ) : null}
    </form>
  );
}
