import {
  Button,
  TextField,
  Select,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Style from "./Style.module.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setPageNumber, setRestaurant } from "../../Actions/Client";

const SearchBox = ({ setData, loading }) => {
  const dispatch = useDispatch();
  const [area, setArea] = useState("");
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState("SelectState");
  const [showerror, setShowError] = useState(false);
  const [charityOnly, setCharityOnly] = useState(false);
  const { selectedState: sd } = useSelector((state) => state.client);

  useEffect(() => {
    fetchState();
    setSelectedState(sd);
  }, [sd]);

  const fetchState = () => {
    var config = {
      method: "get",
      // url: "https://staging.fastor.ai/user/location_filter",
      url: "https://api.covidmealsforindia.com/user/location_filter",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data);
        setState(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    dispatch(setRestaurant([]));
    dispatch(setPageNumber(1));
    if (selectedState === "SelectState") {
      setShowError(true);
    } else {
      // document?.getElementById("content")?.scrollIntoView();
      setShowError(false);
      setData(area, selectedState, charityOnly);
    }
  };

  return (
    <div className={Style.bg}>
      <div className={Style.card}>
        <p>Find food in nearby areas</p>
        <div>
          <Select
            size="medium"
            fullWidth
            style={{ background: "white", marginTop: "20px" }}
            label="Search by Location"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            variant="outlined"
          >
            <MenuItem value={"SelectState"}>Select State</MenuItem>
            {state?.map((state, index) => (
              <MenuItem key={index} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
          {showerror && (
            <span style={{ color: "red", fontSize: "12px" }}>
              Select State First
            </span>
          )}

          <TextField
            size="medium"
            fullWidth
            style={{ background: "white", marginTop: "15px" }}
            label="Enter City/Service Provider (Optional)"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            variant="outlined"
          />
        </div>

        <FormControlLabel
          style={{ marginTop: "5px" }}
          value={charityOnly}
          control={<Checkbox color="primary" />}
          label={<p>Looking for free charity food only</p>}
          labelPlacement="end"
          onChange={(e) => setCharityOnly(e.target.checked)}
        />

        <IconButton style={{ marginTop: "10px" }}>
          <Button onClick={handleSubmit} className={Style.search}>
            {loading ? (
              <CircularProgress size={25} style={{ color: "white" }} />
            ) : (
              "Find Food"
            )}
          </Button>
        </IconButton>
      </div>
    </div>
  );
};

export default SearchBox;
