import React from "react";
import Scene from "../../Images/scene.gif";
import Style from "./Style.module.scss";

const StaticContainer = () => {
  return (
    <div className={Style.static}>
      <h1>
        We Are In This
        <br /> Together
      </h1>
      <div className={Style.points}>
        <div className={Style.pointscontainer}>
          <img
            src={window.location.origin + "/Images/Group 5014.svg"}
            alt="Locality"
          />
          <p>1. Search Your Locality</p>
        </div>
        <div className={Style.pointscontainer}>
          <img
            src={window.location.origin + "/Images/Group 5021.svg"}
            alt="Suppliers"
          />
          <p>2. Find Suppliers Nearby</p>
        </div>
        <div className={Style.pointscontainer}>
          <img
            src={window.location.origin + "/Images/Group 5020.svg"}
            alt="Whatsapp"
          />
          <p>3. Place Order On Whatsapp</p>
        </div>
      </div>
      <img
        src={"https://fastormeal.s3.ap-south-1.amazonaws.com/map.gif"}
        alt="map"
        height="340"
        width="300"
        style={{ marginTop: 10 }}
      />

      <h2>
        Made
        <br /> In India <br />
        For India
      </h2>
    </div>
  );
};

export default StaticContainer;
