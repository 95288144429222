import {
  SET_RESTAURANT,
  SET_SELECTED_STATE,
  SET_SEARCHQUERY,
  SET_PAGENO,
  SET_IS_NEXT,
  SET_TOTAL_COUNT,
  SET_RESET,
  SET_APILOCK,
  SET_CHARITY_ONLY,
} from "../Actions/Client";

const initialState = {
  data: [],
  selectedState: "SelectState",
  searchQuery: "",
  total_count: "",
  pageno: 1,
  next_page: false,
  lockApi: false,
  charity_only: false,
};

const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESTAURANT:
      return {
        ...state,
        data: action.paylod,
      };
    case SET_SELECTED_STATE:
      return {
        ...state,
        selectedState: action.paylod,
      };
    case SET_SEARCHQUERY:
      return {
        ...state,
        searchQuery: action.paylod,
      };
    case SET_PAGENO:
      return {
        ...state,
        pageno: action.paylod,
      };
    case SET_IS_NEXT:
      return {
        ...state,
        next_page: action.paylod,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        total_count: action.paylod,
      };

    case SET_RESET:
      return {
        data: [],
      };
    case SET_APILOCK:
      return {
        ...state,
        lockApi: action.paylod,
      };

    case SET_CHARITY_ONLY:
      return {
        ...state,
        charity_only: action.paylod,
      };
    default:
      return state;
  }
};

export default ClientReducer;
