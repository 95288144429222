import { createSlice } from "@reduxjs/toolkit";

const LoginSlice = createSlice({
  name: "Login",
  initialState: {
    snack: undefined,
    mobile: undefined,
  },
  reducers: {
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setReset: (state, action) => {
      state.snack = undefined;
    },
    setErrorSnack: (state, action) => {
      state.snack = {
        date: new Date(),
        status: action?.payload,
        color: "#f4436",
      };
    },
    ResetErrorSnack: (state, action) => {
      state.snack = undefined;
    },
  },
});

export const {
  setErrorSnack,
  setMobile,
  setReset,
  ResetErrorSnack,
} = LoginSlice.actions;

export default LoginSlice.reducer;
