import axios from "axios";
const apiConfig = {
  // baseUrl: "https://staging.fastor.ai/",
  baseUrl: "https://api.covidmealsforindia.com/",
};

const getAccessToken = async () => {
  try {
    const retrievedItem = await localStorage.getItem("token");
    if (retrievedItem !== null) {
      const authorization = `Bearer ${retrievedItem}`;
      return authorization;
    }
    return undefined;
  } catch (error) {
    // Error retrieving data
  }
};

const instance = axios.create({
  baseURL: apiConfig.baseUrl,
  headers: {
    Accept: "application/json",
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = "/login";
    }
    console.log(error);
    return Promise.resolve();
  }
);

const getLoginClient = async () => {
  instance.defaults.headers.common.Authorization = await getAccessToken();
  return instance;
};

export default getLoginClient;
