import React, { useState } from "react";
import Style from "./Style.module.scss";
import { useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { startLogin } from "../../Actions/Login";
import Meals from "../../Images/Logo.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Bg from "../../Images/Pattern.svg";

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [mobileno, setMobileno] = useState("");
  const [load, setLoad] = useState(false);

  const handleLogin = () => {
    dispatch(startLogin(mobileno, history, setLoad));
  };

  return (
    <div className={Style.container} style={{ backgroundImage: `url(${Bg})` }}>
      <div className={Style.img}>
        <img src={Meals} alt="meals" height="280" width="200" />
      </div>
      <div className={Style.title}>
        <h1>Enter WhatsApp Number</h1>
      </div>
      <div className={Style.description}>
        <p>We will send all the updates to this number</p>
      </div>
      <div className={Style.inputContainer}>
        <TextField
          size="medium"
          fullWidth
          type="number"
          label="Mobile Number"
          onChange={(e) => setMobileno(e.target.value)}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+91</InputAdornment>
            ),
            endAdornment: mobileno.length === 10 && (
              <InputAdornment position="end">
                <CheckCircleIcon
                  fontSize="medium"
                  style={{ color: "#35942B" }}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={Style.btnContainer}>
        <Button
          disabled={mobileno.length !== 10}
          variant="contained"
          color="primary"
          onClick={handleLogin}
        >
          {load ? <CircularProgress style={{ color: "white" }} /> : "GET OTP"}
        </Button>
      </div>
    </div>
  );
}
