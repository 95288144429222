import React from "react";
import Style from "./Style.module.scss";
import Fastor from "../../Images/FastorLogo.svg";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
export default function Footer() {
  const handleTC = () => {
    return (window.location = "https://bit.ly/2S40UQZ"), "_blank";
  };

  const handleFastor = () => {
    return (window.location = "https://bit.ly/3vkXYxI"), "_blank";
  };

  const handleSG = () => {
    return (window.location = "https://bit.ly/3dPyT7Z"), "_blank";
  };
  return (
    <div style={{ marginTop: "15%" }}>
      <div className={Style.footer}>
        <div style={{ padding: "0px" }}>
          <div className={Style.footer_text}>
            <h1>
              This is a non-profit public initiative by <br /> <br />
              <IconButton onClick={handleSG}>
                {" "}
                <span>Chef Saransh Goila </span>{" "}
              </IconButton>
              <br /> &
            </h1>
          </div>
          <div className={Style.footer_logo}>
            <IconButton onClick={handleFastor}>
              {" "}
              <img src={Fastor} alt="meals" />
            </IconButton>
          </div>
        </div>
      </div>
      <div
        style={{ paddingBottom: "10px", fontWeight: "bold" }}
        className={Style.conditions}
        onClick={handleTC}
      >
        View T&C
      </div>
    </div>
  );
}
