import React, { useState, useEffect } from "react";
import Style from "./Style.module.scss";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import SearchBox from "./SearchBox";
import StaticContainer from "./StaticContainer";
import { Link } from "react-router-dom";
import { getToken } from "../../Core/Session";
import Card from "../../Components/ClientDashboard/Card";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";
import {
  setRestaurant,
  setSelectedState,
  setSearchQuery,
  setPageNumber,
  setIsNext,
  setTotalCount,
  setCharityOnly,
} from "../../Actions/Client";
import Logo from "../../Images/Logo.svg";
import Fastor from "../../Images/FastorLogo.svg";
import Whatsapp from "../../Images/whatsapp.svg";
import { setApiLock } from "../../Actions/Client";
import Declaimer from "./Declaimer";
const ClientDashboard = () => {
  const dispatch = useDispatch();
  const {
    data,
    selectedState,
    searchQuery,
    total_count,
    pageno,
    next_page,
    charity_only,
  } = useSelector((state) => state.client);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [bool, setBool] = useState(true);
  const [snackError, setsnackError] = useState(false);
  const { lockApi } = useSelector((state) => state.client);

  window.onscroll = function () {
    if (
      window.innerHeight + window.scrollY + 100 >=
      document.body.offsetHeight
    ) {
      if (next_page) {
        if (lockApi === false) {
          dispatch(setPageNumber(pageno + 1));
        }
      }
    }
  };
  window.onbeforeunload = function () {
    dispatch(setRestaurant([]));
    dispatch(setSelectedState("SelectState"));
    dispatch(setSearchQuery(""));
  };

  useEffect(() => {
    if (localStorage.getItem("isback") === "true") {
      localStorage.setItem("isback", false);
    } else {
      fetchData();
    }
  }, [pageno, bool]);

  const fetchData = () => {
    dispatch(setApiLock(true));
    setLoading(true);
    // dispatch(setLockApi(true));
    var config = {
      method: "get",
      // url: `https://staging.fastor.ai/user/restaurants?page_no=${pageno}&search_query=${searchQuery}&location_filter=${selectedState}&charity_only=${charity_only}`,
      url: `https://api.covidmealsforindia.com/user/restaurants?page_no=${pageno}&search_query=${searchQuery}&location_filter=${selectedState}&charity_only=${charity_only}`,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setsnackError(false);
        if (response.data.data.restaurants.length === 0) {
          setsnackError(true);
        }
        dispatch(setRestaurant([...data, ...response.data.data.restaurants]));
        dispatch(setApiLock(false));
        dispatch(setTotalCount(response.data.data.total_count));
        dispatch(setIsNext(response.data.data.next_page));

        if (pageno === 1) {
          document?.getElementById("content")?.scrollIntoView();
        }

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleTC = () => {
    return (window.location = "https://bit.ly/2S40UQZ"), "_blank";
  };

  const handleFastor = () => {
    return (window.location = "https://bit.ly/3vkXYxI"), "_blank";
  };

  const handleSG = () => {
    return (window.location = "https://bit.ly/3dPyT7Z"), "_blank";
  };

  const handleWhatsapp = (e) => {
    e.stopPropagation();
    return (window.location = `http://wa.me/+919167659781?text=Hey,%20I'm%20unable%20to%20find%20a%20service%20provider%20in%20my%20city,%20can%20you%20please%20help%20me%20out?`);
  };
  return (
    <div
      style={{
        backgroundImage: `url(${"https://fastormeal.s3.ap-south-1.amazonaws.com/background.svg"})`,
      }}
      className={Style.container}
    >
      <div className={Style.header}>
        <IconButton>
          {getToken() ? (
            <Link to="/profile" style={{ textDecoration: "none" }}>
              <Button
                onClick={() => {
                  dispatch(setRestaurant([]));
                  dispatch(setSelectedState("SelectState"));
                  dispatch(setSearchQuery(""));
                }}
                className={Style.switch}
              >
                Switch To Dashboard
              </Button>
            </Link>
          ) : (
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Button
                onClick={() => {
                  dispatch(setRestaurant([]));
                  dispatch(setSelectedState("SelectState"));
                  dispatch(setSearchQuery(""));
                }}
                className={Style.register}
              >
                Register As Service Provider
              </Button>
            </Link>
          )}
        </IconButton>
        <img src={Logo} alt="meals" height="140" width="160" />
        <Declaimer />
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setShow(!show)}
          className={Style.searchbtn}
        >
          <SearchIcon style={{ color: "#F27528" }} />
          <p>Where should we find you a meal?</p>
        </div>
      </div>

      {show && (
        <SearchBox
          loading={loading}
          setData={(location, state, charity) => {
            console.log(location, state, charity);
            setBool(!bool);
            dispatch(setPageNumber(1));
            dispatch(setCharityOnly(charity));
            dispatch(setSearchQuery(location));
            dispatch(setSelectedState(state));
          }}
        />
      )}

      <div className={Style.illusion}>
        <img src={window.location.origin + "/Images/ill.svg"} />
      </div>

      {snackError && (
        <Snackbar
          open={
            selectedState != "SelectState" &&
            data.length === 0 &&
            snackError === true &&
            loading === false
          }
          autoHideDuration={5000}
          onClose={() => setsnackError(false)}
          message={
            "Oops! We couldn't find a meal provider in this city. Try searching only within your state."
          }
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={() => setsnackError(false)}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        />
      )}

      {data?.length > 0 ? (
        <div className={Style.cardContainer} id="content">
          <div className={Style.cardContainerTitle}>
            <h2>Showing {total_count} meal providers in</h2>
            <h1>{selectedState}</h1>
          </div>
          <div>
            {data.map((data, index) => {
              return (
                <Card
                  key={index}
                  data={data}
                  searchQuery={searchQuery}
                  selectedState={selectedState}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      ) : loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
            backgroundColor: "white",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <StaticContainer />
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "40px",
          marginTop: "20px",
        }}
      >
        <div style={{ paddingBottom: "10px", textAlign: "center" }}>
          {" "}
          Showing {data.length} Food Providers
        </div>
        <div style={{ paddingBottom: "10px", textAlign: "center" }}>
          Having problem finding<br></br> a service provider in your city?
        </div>
        <Button
          style={{
            backgroundColor: "#44D48E",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
            padding: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div>
            <img
              style={{ width: "20px", height: "20px" }}
              src={Whatsapp}
              alt="meals"
            />
          </div>
          <div
            onClick={handleWhatsapp}
            style={{ color: "white", fontWeight: "bold", paddingLeft: "10px" }}
          >
            Contact Us
          </div>
        </Button>
      </div>
      <div className={Style.footer}>
        <div style={{ padding: "0px" }}>
          <div className={Style.footer_text}>
            <h1>
              This is a non-profit public initiative by <br /> <br />
              <IconButton onClick={handleSG}>
                {" "}
                <span>Chef Saransh Goila </span>{" "}
              </IconButton>
              <br /> &
            </h1>
          </div>
          <div className={Style.footer_logo}>
            <IconButton onClick={handleFastor}>
              {" "}
              <img src={Fastor} alt="meals" />
            </IconButton>
          </div>
        </div>
      </div>
      <div
        style={{ paddingBottom: "10px", fontWeight: "bold" }}
        className={Style.conditions}
        onClick={handleTC}
      >
        View T&C
      </div>
    </div>
  );
};

export default ClientDashboard;
