import { IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useParams } from "react-router";
import Style from "./Style.module.scss";
import axios from "axios";

const Images = () => {
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var config = {
      method: "get",
      // url: `https://staging.fastor.ai/user/restaurant/${id}`,
      url: `https://api.covidmealsforindia.com/user/restaurant/${id}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setData(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(data);

  return (
    <div className={Style.container}>
      <div className={Style.bcicon}>
        <IconButton>
          <ArrowBackIosIcon
            onClick={() => {
              history.goBack();
            }}
            style={{ alignSelf: "flex-start" }}
          />
        </IconButton>
        <h2>Images from {data?.restaurant_name}</h2>
      </div>
      <img src={data?.restaurant_image} alt="res" />
      {data?.menu_images.map((img, ind) => (
        <img src={img} alt="menu" />
      ))}
    </div>
  );
};

export default Images;
