import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSwitch, setRestaurant } from "../../Actions/Restaurant";
import ShareButton from "./ShareButton";
import Style from "./Style.module.scss";
import Edit from "../../Images/edit.svg";
import { Button, IconButton, Switch, Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import noProfile from "../../Images/ProfilePP.svg";
import noMenu from "../../Images/NoMenu.svg";
import Logo from "../../Images/Logo.svg";
import Fastor from "../../Images/FastorLogo.svg";

export default function Profile() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.restaurant);
  const history = useHistory();
  console.log("data in profile", data);

  const [snowSnack, setShowSnack] = useState(false);
  const [operation, setOperation] = useState(
    data?.status === "ACTIVE" ? true : false
  );
  useEffect(() => {
    if (!data && localStorage.getItem("newUser") === "true") {
      history.push("/register");
    }
    dispatch(setRestaurant());
  }, [dispatch]);

  useEffect(() => {
    setOperation(data?.status === "ACTIVE" ? true : false);
  }, [data]);
  const handleOperation = () => {
    setOperation(!operation);
    dispatch(handleSwitch(operation));
    setShowSnack(true);
  };

  const handleTC = () => {
    return (window.location = "https://bit.ly/2S40UQZ"), "_blank";
  };

  const handleFastor = () => {
    return (window.location = "https://bit.ly/3vkXYxI"), "_blank";
  };

  const handleSG = () => {
    return (window.location = "https://bit.ly/3dPyT7Z"), "_blank";
  };

  return (
    <div className={Style.container}>
      <div className={Style.header}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button className={Style.customerButton}>
            Switch To Customer View
          </Button>
        </Link>
        <div style={{ paddingTop: "10px" }} className={Style.imgContainer}>
          <img src={Logo} alt="logo" height="70" width="70" />
        </div>
        <div style={{ width: data?.restaurant_image ? 280 : 250 }}>
          {data?.restaurant_image ? (
            <img
              src={data?.restaurant_image}
              alt="restaurant"
              style={{ height: 60, width: 60, borderRadius: 30, marginTop: 10 }}
            />
          ) : (
            <IconButton>
              <img
                onClick={() => history.push("editdetails")}
                src={noProfile}
                alt="restaurant"
                style={{
                  height: 60,
                  width: 60,
                  borderRadius: 30,
                  marginTop: 10,
                }}
              />
            </IconButton>
          )}

          <h1>My Profile</h1>

          <Link to="editdetails">
            <img src={Edit} alt="edit" />
          </Link>
        </div>
      </div>
      <div className={Style.content}>
        <div className={Style.menuImages}>
          <h1>Menu Images</h1>
          {data?.menu_images.length > 0 ? (
            <div
              onClick={() => history.push(`/${data.res_code}/images`)}
              className={Style.menuImagesContainer}
            >
              {data?.menu_images?.map((img, index) => (
                <img src={img} alt="menu-uploaded" key={index} />
              ))}
            </div>
          ) : (
            <IconButton>
              <img
                onClick={() => history.push("editdetails")}
                style={{ height: "95px", width: "95px" }}
                src={noMenu}
                alt="menu-image"
              />
            </IconButton>
          )}
        </div>

        <div className={Style.subtitle}>
          <h2>Scheduling Details</h2>
        </div>
        <div className={Style.timedetails}>
          <div className={Style.timedetailsFlex}>
            <div className={Style.timedetailsContainer}>
              <p>Start Time</p>
              <h2>
                {data?.service_days &&
                  Object?.keys(data?.service_days)
                    ?.slice(0 - 1)
                    ?.map((i) => data?.service_days[i]?.open_at)}
              </h2>
            </div>
            <div className={Style.timedetailsContainer}>
              <p>End Time</p>
              <h2>
                {data?.service_days &&
                  Object?.keys(data?.service_days)
                    ?.slice(0 - 1)
                    ?.map((i) => data?.service_days[i]?.close_at)}
              </h2>
            </div>
          </div>
        </div>
        <div className={Style.details}>
          <div className={Style.detailsContainer}>
            <p>Days</p>

            <h2>
              {data?.service_days &&
                Object?.keys(data?.service_days)
                  ?.map((i) => i)
                  ?.join(", ")}
            </h2>
          </div>
        </div>
        <div className={Style.subtitle}>
          <h2>Personal Information</h2>
        </div>
        <div className={Style.details}>
          <div className={Style.detailsContainer}>
            <p>Mobile Number</p>
            <h2>{data?.phone_numbers[0]}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Full Name</p>
            {/* Change tmp_merchant */}
            <h2>{data?.tmp_merchant?.fname}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Alternate Contact Number</p>
            <h2>{data?.phone_numbers[1] ? data.phone_numbers[1] : "-"}</h2>
          </div>
        </div>
        <div className={Style.subtitle}>
          <h2>Business Details</h2>
        </div>
        <div className={Style.details}>
          <div className={Style.detailsContainer}>
            <p>Business Name</p>
            <h2>{data?.restaurant_name}</h2>
          </div>

          <div className={Style.detailsContainer}>
            <p>Service Type</p>
            <h2>{data?.service_type ? data.service_type : "-"}</h2>
          </div>

          <div className={Style.detailsContainer}>
            <p>Business Email</p>

            <h2>{data?.tmp_merchant?.email || "-"}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Services Active</p>
            <h2>
              {[
                `${data?.is_delivery && "Delivery"}`,
                data?.is_pickup ? "Pickup" : undefined,
              ].join(", ")}
            </h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Address 1</p>
            {/* Change tmp_merchant */}
            <h2>{data?.tmp_location?.address_1}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Address 2</p>
            {/* Change tmp_merchant */}
            <h2>{data?.tmp_location?.address_2 || "-"}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>City</p>
            {/* Change tmp_merchant */}
            <h2>{data?.tmp_location?.city}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>State</p>
            {/* Change tmp_merchant */}
            <h2>{data?.tmp_location?.state}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Zip Code</p>
            {/* Change tmp_merchant */}
            <h2>{data?.tmp_location?.zip_code}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Phone Number</p>
            {/* Change tmp_merchant */}
            <h2>{data?.tmp_merchant?.phone || "-"}</h2>
          </div>
        </div>
        <div className={Style.subtitle}>
          <h2>Other Details</h2>
        </div>
        <div className={Style.details}>
          <div className={Style.detailsContainer}>
            <p>Website Link</p>
            <h2>{data?.website || "-"}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Delivery Modes</p>
            <h2>
              {data?.delivery_mode &&
                Object?.keys(data?.delivery_mode)
                  ?.map((i) => (data?.delivery_mode[i] ? i : null))
                  ?.filter((i) => i)
                  ?.join(", ")}
            </h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Covid Diet Food</p>
            <h2>{data?.is_covid_special ? "Yes" : "-"}</h2>
          </div>
          <div className={Style.detailsContainer}>
            <p>Charity</p>
            <h2>{data?.is_charity ? "Yes" : "-"}</h2>
          </div>
        </div>
        <div className={Style.switch}>
          <p>{operation ? "Listing is Public" : "Listing is Deactivated"}</p>
          <Switch
            checked={operation}
            onChange={handleOperation}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
        <div style={{ paddingBottom: "20%" }}></div>
        <div className={Style.sharecontainer}>
          {" "}
          <ShareButton
            name={data?.restaurant_name}
            state={data?.tmp_location?.state}
            res_code={data?.res_code}
          />{" "}
        </div>
        {/* Snackbar    */}
        <Snackbar
          open={snowSnack}
          autoHideDuration={3000}
          message={`Listing ${operation ? "Activated" : "Deativated"}`}
          onClose={() => setShowSnack(false)}
        />
        <div className={Style.footer}>
          <div style={{ padding: "0px" }}>
            <div className={Style.footer_text}>
              <h1>
                This is a non-profit public initiative by <br /> <br />
                <IconButton onClick={handleSG}>
                  {" "}
                  <span>Chef Saransh Goila </span>{" "}
                </IconButton>
                <br /> &
              </h1>
            </div>
            <div className={Style.footer_logo}>
              <IconButton onClick={handleFastor}>
                {" "}
                <img src={Fastor} alt="meals" />
              </IconButton>
            </div>
          </div>
        </div>
        <div
          style={{ paddingBottom: "10px", fontWeight: "bold" }}
          className={Style.conditions}
          onClick={handleTC}
        >
          View T&C
        </div>
      </div>
    </div>
  );
}
