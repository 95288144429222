import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken } from "../../Core/Session";

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    component={(props) =>
      !getToken() ? <Component {...props} /> : <Redirect to="/register" />
    }
  />
);
