import React, { useState, useEffect } from "react";
import Style from "./Style.module.scss";
import OtpInput from "react-otp-input";
import { useParams, useHistory } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { startOtpLogin, startLogin } from "../../Actions/Login";
import Meals from "../../Images/Logo.svg";
import Snackbar from "../../Components/Snackbar";
import Bg from "../../Images/Pattern.svg";
import { ResetErrorSnack, setReset } from "../../Reducers/Login";

export default function OTP() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const { mobileno } = useParams();
  const [count, setCount] = useState(30);
  const [load, setLoad] = useState(false);
  const { snack } = useSelector((state) => state.login);

  window.onbeforeunload = function () {
    console.log("running");
    dispatch(ResetErrorSnack());
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      count > 0 && setCount((e) => e - 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [count, dispatch]);

  // useEffect(() => {

  //   return () => {
  //     dispatch(setReset());
  //   };
  // }, [dispatch]);

  const handleLogin = () => {
    dispatch(startOtpLogin(mobileno, OTP, history, setLoad));
  };

  const handleResend = () => {
    setCount(30);
    dispatch(startLogin(mobileno, history, setLoad));
  };

  console.log(snack);

  return (
    <div className={Style.container} style={{ backgroundImage: `url(${Bg})` }}>
      <div className={Style.img}>
        <img src={Meals} alt="meals" height="280" width="200" />
      </div>
      <div className={Style.title}>
        <h1>
          Enter OTP sent on <br /> WhatsApp by Fastor
        </h1>
      </div>
      <div className={Style.description}>
        <p>On the number below</p>
      </div>
      <div className={Style.mobilenumber}>
        <h1> +91 {mobileno}</h1>
        <span onClick={() => history.push("/login")}>Change</span>
      </div>
      <div className={Style.inputContainer}>
        <OtpInput
          shouldAutoFocus={true}
          value={OTP}
          containerStyle={{
            display: "flex",
            justifyContent: "space-around",
          }}
          inputStyle={{
            color: "black",
            width: "40px",
            height: "40px",
            background: "white",
            border: "1px solid #888888",
            borderRadius: "2px",
            fontWeight: 700,
            fontSize: 16,
          }}
          onChange={(e) => setOTP(e)}
          numInputs={6}
          isInputNum={true}
          isInputSecure={false}
          separator={<span></span>}
        />
      </div>
      <div className={Style.btnContainer}>
        <Button
          disabled={OTP.length < 6}
          variant="contained"
          color="primary"
          onClick={handleLogin}
        >
          {load ? <CircularProgress style={{ color: "white" }} /> : "VERIFY"}
        </Button>
      </div>
      {count !== 0 && (
        <div className={Style.otp}>
          <p>00:{count > 9 ? count : "0" + count} </p>
        </div>
      )}
      {count === 0 && (
        <div className={Style.otp2}>
          <p>Didn’t receive OTP ?</p>
          <span onClick={handleResend}>Resend OTP</span>
        </div>
      )}
      {snack ? (
        <Snackbar key={snack.date} status={snack.status} color={snack.color} />
      ) : null}
    </div>
  );
}
