import React, { useEffect } from "react";
import { Button, IconButton, Modal } from "@material-ui/core";
import Style from "./Style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setOpenModal } from "../../Reducers/Donation";
import { ClickDonateNow } from "../../Actions/Donation";
export default function DonationModal() {
  const dispatch = useDispatch();
  const { data: tempdata, show, modalIndex } = useSelector(
    (state) => state.donation
  );
  const data = tempdata[modalIndex];

  return (
    <Modal
      className={Style.modal}
      open={show}
      onClose={() => dispatch(setOpenModal({ open: false, undefined }))}
    >
      <div className={Style.paper}>
        <img src={data?.img} alt="logo" />
        <h1 style={{ marginTop: "20px" }}>I want to donate to {data?.name}</h1>
        <p
          style={{
            marginTop: "20px",
            fontWeight: "600",
            color: "#434343",
            fontSize: "12px",
            lineHeight: "16px",
          }}
        >
          {data?.description}
        </p>
        <h2 style={{ marginTop: "20px" }}>Redirect me to </h2>
        <h2>{data?.website}</h2>
        <IconButton style={{ marginTop: "20px" }}>
          <Button
            className={Style.modalbutton}
            onClick={ClickDonateNow(data)}
            variant="contained"
          >
            Donate Now
          </Button>
        </IconButton>
      </div>
    </Modal>
  );
}
