import ins from "../Core/Api";
import { setData, setError } from "../Reducers/Restaurant";

export const startRegister = (payload, history, setLoad) => {
  return async (dispatch) => {
    const instance = await ins();
    instance
      .post("merchant/restaurant", payload)
      .then(async (response) => {
        console.log(response);
        setLoad(false);

       localStorage.setItem("newUser",false)
        history.push("/profile");
      })
      .catch((err) => {
        dispatch(setError("Something went wrong"));
        console.log(err);
      })
      .finally(() => {
        setLoad(false);
      });
  };
};

export const changeDetails = (payload, history, setLoad) => {
  return async (dispatch) => {
    const instance = await ins();
    instance
      .patch("merchant/restaurant", payload)
      .then(async (response) => {
        setLoad(false);
        history.push("/profile");
      })
      .catch((err) => {
        dispatch(setError("Something went wrong"));
        console.log(err);
      })
      .finally(() => {
        setLoad(false);
      });
  };
};

export const setRestaurant = () => {
  return async (dispatch) => {
    const instance = await ins();
    instance
      .get("merchant/restaurant")
      .then(async (response) => {
        dispatch(setData(response.data.data || null));
        console.log("response from api", response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setImageDelete = (url) => {
  return async (dispatch) => {
    const instance = await ins();
    instance
      .post("merchant/image/upload/remove", {
        img_url: url,
      })
      .then(async (response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const handleSwitch = (val) => {
  return async (dispatch) => {
    const instance = await ins();
    instance
      .post("merchant/restaurant/close", {
        is_close: val,
      })
      .then(async (response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
