import { createSlice } from "@reduxjs/toolkit";

const RestaurantSlice = createSlice({
  name: "Restaurant",
  initialState: {
    data: undefined,
    address: undefined,
    snack: undefined,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setError: (state, action) => {
      state.snack = {
        date: new Date(),
        status: action?.payload,
        color: "#f4436",
      };
    },
    ResetError: (state, action) => {
      console.log("running");
      state.snack = undefined;
    },
  },
});

export const {
  setData,
  setAddress,
  setError,
  ResetError,
} = RestaurantSlice.actions;

export default RestaurantSlice.reducer;
