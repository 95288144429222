import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Style from "./Style.module.scss";
import { IconButton } from "@material-ui/core";
const TopBar = () => {
  const history = useHistory();
  return (
    <div className={Style.topbar}>
      <IconButton
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ArrowBackIosIcon
          onClick={() => {
            history.push('/');
          }}
          style={{ alignSelf: "flex-start" }}
        />
      </IconButton>
      <p>{"DONATIONS"}</p>
    </div>
  );
};

export default TopBar;
