import React from "react";
import Style from "./Style.module.scss";
import Donation from "../../Images/Donation.svg";
import { Button, IconButton } from "@material-ui/core";
import NewTag from "../../Images/Newtag.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setRestaurant,
  setSelectedState,
  setSearchQuery,
} from "../../Actions/Client";

export default function Declaimer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handlengoClick = () => {
    dispatch(setRestaurant([]));
    dispatch(setSelectedState("SelectState"));
    dispatch(setSearchQuery(""));
    history.push("/donation");
  };
  return (
    <div
      style={{
        backgroundImage: `url(${NewTag})`,
        backgroundRepeat: "no-repeat",
      }}
      className={Style.desclaimer}
    >
      <div>
        <img src={Donation} alt="donation" />
        <p>
          CovidMealsforIndia is enabling verified food NGOs to get donations
          where you can directly donate them on their websites.
        </p>
      </div>

      <IconButton style={{ float: "right" }}>
        <Button
          className={Style.dec_button}
          onClick={() => handlengoClick()}
          variant="contained"
        >
          Go to Donations
        </Button>
      </IconButton>
    </div>
  );
}
