import ins from "../Core/Api";
import { setData } from "../Reducers/Donation";
export const FetchDonation = () => {
  return async (dispatch) => {
    const instance = await ins();
    instance
      .get("/user/donation/")
      .then(async (response) => {
        console.log(response);
        dispatch(setData(response.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const ClickDonateNow = (data) => {
  return async () => {
    const instance = await ins();
    instance
      .patch("/user/donation/click", {
        donation_id: data.id,
      })
      .then(async (response) => {
        console.log(response.data);
        if (response.data.status_code === 200) {
          // window.open(data.website);
          window.location.assign(data.website, '_blank')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
