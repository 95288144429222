import ins from "../Core/Api";
import { setToken } from "../Core/Session";
import { setErrorSnack, setMobile } from "../Reducers/Login";

export const startLogin = (number, history, setLoad) => {
  return async (dispatch) => {
    setLoad(true);
    const instance = await ins();
    instance
      .get(`merchant/otp?phone=${number}`)
      .then(async (response) => {
        console.log(response);
        history.push(`/otp/${number}`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoad(false);
      });
  };
};

export const startOtpLogin = (number, otp, history, setLoad) => {
  return async (dispatch) => {
    setLoad(true);
    const instance = await ins();
    instance
      .post(`/merchant/login`, {
        phone: number,
        otp: otp,
      })
      .then(async (response) => {
        console.log(response);
        setToken(response.data.data.token);
        dispatch(setMobile(number));
        if (response.data.data.new_user) {

          localStorage.setItem("newUser",true)
          history.push("/register");
        }
        else {
          localStorage.setItem("newUser",false)
          history.push("/profile");
        }
      })
      .catch((err) => {
        dispatch(setErrorSnack("Wrong OTP"));
      })
      .finally(() => {
        setLoad(false);
      });
  };
};
