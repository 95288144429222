import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  Button,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setImageDelete, startRegister } from "../../Actions/Restaurant";
import Style from "./Style.module.scss";
import Menu from "../../Images/menu.svg";
import Gallery from "../../Images/gallery.svg";
import ins from "../../Core/Api";
import { useHistory } from "react-router";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Autocomplete from "./Autocomplete";
import Logo from "../../Images/Logo.svg";
import { states } from "../../Core/State";
import { ResetError } from "../../Reducers/Restaurant";
import Snackbar from "../../Components/Snackbar";

export default function Register() {
  window.onbeforeunload = function () {
    console.log("hit");
    dispatch(ResetError());
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [number, setNumber] = useState();
  const [fname, setFname] = useState();
  const [altContact, setAltContact] = useState();
  const [link, setLink] = useState();
  const [describe, setDescribe] = useState();
  const [startTime, setStartTime] = useState("07:30");
  const [endTime, setEndTime] = useState("21:00");
  const [services, setServices] = useState({
    Direct: true,
    "3rd Party": false,
    "Via Customer Only": false,
  });
  const [modes, setModes] = useState({
    Direct: false,
    Swiggy: false,
    Zomato: false,
  });
  const [days, setDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const [charity, setCharity] = useState(false);
  const [hideaddress1, setHideadress1] = useState(false);
  const [diet, setDiet] = useState("Yes");
  const [menu, setMenu] = useState([]);
  const [restaurant_image, setRestaurant_image] = useState("");
  const [timing, setTiming] = useState({});
  const [menuLoad, setMenuLoad] = useState();
  const [restaurantLoad, setRestaurantLoad] = useState();
  const [load, setLoad] = useState(false);
  const [business_Type, setBusinessType] = useState("");

  const { mobile } = useSelector((state) => state.login);
  const { snack } = useSelector((state) => state.restaurant);
  console.log(snack);
  const { data } = useSelector((state) => state.restaurant);
  const [validationError, setValidationError] = useState({
    service: false,
  });

  useEffect(() => {
    if (localStorage.getItem("newUser") === "false") {
      console.log("ABC2");
      history.push("/profile");
    }
  }, []);
  const handleMenu = async (e) => {
    setMenuLoad(true);
    const files = e.target.files;
    const formData = new FormData();
    formData.append("image", files[0]);
    const instance = await ins();
    instance
      .post("merchant/image/upload", formData)
      .then((response) => {
        setMenu([...menu, response.data.data.url]);
      })
      .finally(() => {
        setMenuLoad(false);
      });
  };

  //update url
  const handleRestaurant = async (e) => {
    setRestaurantLoad(true);
    const files = e.target.files;
    const formData = new FormData();
    formData.append("image", files[0]);
    const instance = await ins();
    instance
      .post("merchant/image/upload", formData)
      .then((response) => {
        setRestaurant_image(response.data.data.url);
      })
      .finally(() => {
        setRestaurantLoad(false);
      });
  };

  const handleRestaurantDelete = () => {
    dispatch(setImageDelete(restaurant_image));
    setRestaurant_image("");
  };

  const handleMenuDelete = (url) => {
    dispatch(setImageDelete(url));
    const temp = [...menu];
    const result = temp.filter((i) => i !== url);
    setMenu(result);
  };

  const handleServices = (e) => {
    setServices({
      ...services,
      [e.target.name]: e.target.checked,
    });
  };

  const handleModes = (e) => {
    setModes({
      ...modes,
      [e.target.name]: e.target.checked,
    });
  };

  const handleRadio = (e) => {
    setDiet(e.target.value);
  };

  const handleStartTime = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTime = (e) => {
    console.log(e.target.value);
    setEndTime(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      restaurant_name: name,
      email,
      merchant_name: fname,
      phone_number: altContact,
      website: link,
      is_pickup: false,
      is_delivery: true,
      is_charity: charity,
      is_covid_special: diet,
      menu_images: menu,
      restaurant_image,
      description: describe,
      delivery_mode: services,
      service_type: business_Type,
      hide_my_location: hideaddress1,
      location: {
        address_1: address1,
        address_2: address2,
        zip_code: zip,
        state: state,
        city: city,
      },
      timing: timing,
    };

    const temp = {
      service: false,
    };
    if (
      !(
        services?.Direct ||
        services["3rd Party"] ||
        services["Via Customer Only"]
      )
    ) {
      document?.getElementById("service").scrollIntoView();
      setValidationError({
        ...temp,
        ...{ service: true },
      });
    } else {
      setLoad(true);
      dispatch(startRegister(data, history, setLoad));
    }
  };

  const handleSchedule = (e) => {
    setDays({
      ...days,
      [e.target.name]: e.target.checked,
    });
    const tempDay = {
      ...days,
      [e.target.name]: e.target.checked,
    };
    let temp = {};
    Object.keys(tempDay).map((i) => {
      if (tempDay[i] === false) return;
      temp[i] = {
        open_at: startTime,
        close_at: endTime,
      };
    });
    setTiming({
      // ...timing,
      ...temp,
    });
  };

  useEffect(() => {
    setNumber(mobile);
  }, [mobile]);

  const isEnabled = true;
  // business_Type &&
  // name?.length > 0 &&
  // (services?.Direct ||
  //   services["3rd Party"] ||
  //   services["Via Customer Only"]) &&
  // address1 &&
  // state &&
  // city &&
  // fname;

  return (
    <form className={Style.container} onSubmit={handleSubmit}>
      <div className={Style.header}>
        <div className={Style.imgContainer}>
          <img src={Logo} alt="logo" height="80" width="80" />
        </div>
        <h1>Register</h1>
      </div>
      <div className={Style.content}>
        <div className={Style.description}>
          <p> Please fill the following details to register</p>
        </div>
        <div className={Style.inputContainer}>
          <h2>Business Details</h2>
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: "15px" }}
            required
          >
            <InputLabel id="business_Type">Business Type</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={business_Type}
              onChange={(e) => setBusinessType(e.target.value)}
              label="Business Type"
              required
            >
              <MenuItem value={"Home Chef"}>{"Home Chef"}</MenuItem>
              <MenuItem value={"Restaurant"}>{"Restaurant"}</MenuItem>
              <MenuItem value={"Cloud Kitchen"}>{"Cloud Kitchen"}</MenuItem>
              <MenuItem value={"Tiffin Service"}>{"Tiffin Service"}</MenuItem>
            </Select>
            <FormHelperText style={{ color: "red" }}>
              {validationError.business_Type
                ? "Please Select Business Type"
                : ""}
            </FormHelperText>
          </FormControl>
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px", textTransform: "capitalize" }}
            label="Business Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            inputProps={{ style: { textTransform: "capitalize" } }}
            required
          />
          <TextField
            type="email"
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Business Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            required
          />

          <div className={Style.checkboxGroup}>
            <h1>Delivery Modes</h1>
            <FormGroup
              error={validationError.service}
              id="service"
              aria-label="position"
              row
            >
              <FormControlLabel
                name="Direct"
                checked={services.Direct}
                onChange={handleServices}
                control={<Checkbox color="primary" />}
                label="Direct"
              />
              <FormControlLabel
                name="3rd Party"
                checked={services["3rd Party"]}
                onChange={handleServices}
                control={<Checkbox color="primary" />}
                label="3rd Party"
              />
              <FormControlLabel
                name="Via Customer Only"
                checked={services["Via Customer Only"]}
                onChange={handleServices}
                control={<Checkbox color="primary" />}
                label="Via Customer Only"
              />
              <FormHelperText style={{ color: "red" }}>
                {validationError.service ? "Please Select Service Type" : ""}
              </FormHelperText>
            </FormGroup>
          </div>

          <TextField
            size="medium"
            fullWidth
            error={validationError.address1}
            style={{ marginTop: "15px" }}
            label="Address1 (Building/House No.)"
            onChange={(e) => setAddress1(e.target.value)}
            value={address1}
            variant="outlined"
            required
            helperText={
              validationError.address1
                ? "Please Enter Address1 (Building/House No.)"
                : ""
            }
          />
          <div>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                checked={hideaddress1}
                onChange={(e) => setHideadress1(e.target.checked)}
                control={<Checkbox color="primary" />}
                label="Hide building/house no. from public visibility"
              />
            </FormGroup>
          </div>
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Address2 (Area/Locality)"
            onChange={(e) => setAddress2(e.target.value)}
            value={address2}
            variant="outlined"
          />

          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="City"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            variant="outlined"
            required
          />
          {/* <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="State"
            onChange={(e) => setState(e.target.value)}
            value={state}
            variant="outlined"
            required
          /> */}
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: "15px" }}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              State
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state}
              onChange={(e) => setState(e.target.value)}
              label="State"
            >
              {states.map((state, index) => (
                <MenuItem value={state} key={index}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="number"
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Zip code"
            onChange={(e) => setZip(e.target.value)}
            value={zip}
            variant="outlined"
            required
          />
          <h2>Personal Information</h2>
          <TextField
            type="number"
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Mobile Number"
            // onChange={(e) => setNumber(e.target.value)}
            value={number}
            variant="outlined"
            disabled={true}
          />
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Full Name"
            onChange={(e) => setFname(e.target.value)}
            value={fname}
            variant="outlined"
            inputProps={{ style: { textTransform: "capitalize" } }}
            required
          />
          <TextField
            type="number"
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Alternative Contact Number (Optional)"
            onChange={(e) => setAltContact(e.target.value)}
            value={altContact}
            variant="outlined"
          />
          <h2>Scheduling</h2>
          <div className={Style.scheduling}>
            <TextField
              size="medium"
              id="time"
              style={{ flexGrow: 1 }}
              label="Start Time"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              value={startTime}
              onChange={handleStartTime}
              variant="outlined"
            />
            <TextField
              size="medium"
              id="time"
              style={{ marginLeft: "20px", flexGrow: 1 }}
              label="End Time"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              value={endTime}
              onChange={handleEndTime}
              inputProps={{
                step: 300, // 5 min
              }}
              variant="outlined"
            />
          </div>

          <div className={Style.schedulingbtns}>
            <FormGroup aria-label="position" row>
              {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                (data, index) => (
                  <FormControlLabel
                    value={data}
                    name={data}
                    onChange={handleSchedule}
                    checked={days.data}
                    control={<Checkbox color="primary" />}
                    label={data}
                    labelPlacement="right"
                  />
                )
              )}
            </FormGroup>
          </div>
          <h2>Other Details</h2>
          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Website link(social media link or menu link)"
            onChange={(e) => setLink(e.target.value)}
            value={link}
            variant="outlined"
          />

          <div className={Style.radioGroup}>
            <h1>Covid Diet Food</h1>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={diet}
              onChange={handleRadio}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </div>

          <TextField
            size="medium"
            fullWidth
            style={{ marginTop: "15px" }}
            label="Describe meal in max 120 charachters"
            onChange={(e) => setDescribe(e.target.value)}
            value={describe}
            variant="outlined"
            multiline
          />

          <div className={Style.checkboxGroup}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                checked={charity}
                onChange={(e) => setCharity(e.target.checked)}
                control={<Checkbox color="primary" />}
                label="Charity(Click this if you are providing food for free)"
              />
            </FormGroup>
          </div>

          <div className={Style.menuImages}>
            <h1>
              Upload Menu Images{" "}
              <span style={{ color: "red" }}>(2MB Max) </span>
            </h1>
            <div className={Style.menuImagesContainer}>
              {menu.map((img, index) => (
                <div
                  style={{
                    position: "relative",
                  }}
                  onClick={() => handleMenuDelete(img)}
                >
                  <DeleteOutlineOutlinedIcon
                    style={{
                      position: "absolute",
                      top: 44,
                      left: 35,
                      color: "#c4c4c4",
                      zIndex: 9999,
                    }}
                  />
                  <img
                    src={img}
                    alt="menu-uploaded"
                    key={index}
                    style={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      width: 95,
                      height: 95,
                      border: "1px solid #888888",
                      borderRadius: 8,
                      marginTop: 10,
                      marginRight: 10,
                    }}
                  />
                </div>
              ))}
              <label>
                {menuLoad ? (
                  <CircularProgress />
                ) : (
                  <img src={Menu} alt="menu" />
                )}
                <input type="file" onChange={handleMenu} />
              </label>
            </div>
          </div>
          <div className={Style.menuImages}>
            <h1>
              Upload Profile Pic{" "}
              <span style={{ color: "red" }}>(2MB Max) </span>
            </h1>

            <div className={Style.menuImagesContainer}>
              {restaurant_image.length > 0 ? (
                <div
                  style={{
                    position: "relative",
                  }}
                  onClick={handleRestaurantDelete}
                >
                  <DeleteOutlineOutlinedIcon
                    style={{
                      position: "absolute",
                      top: 44,
                      left: 35,
                      color: "#c4c4c4",
                      zIndex: 9999,
                    }}
                  />

                  <img
                    src={restaurant_image}
                    alt="menu-uploaded"
                    style={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      width: 95,
                      height: 95,
                      border: "1px solid #888888",
                      borderRadius: 8,
                      marginTop: 10,
                    }}
                  />
                </div>
              ) : (
                <label>
                  {restaurantLoad ? (
                    <CircularProgress />
                  ) : (
                    <img src={Gallery} alt="menu" />
                  )}
                  <input type="file" onChange={handleRestaurant} />
                </label>
              )}
            </div>
          </div>
        </div>
        <div className={Style.btnContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isEnabled ? false : true}
          >
            {load ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              "Register Now"
            )}
          </Button>
        </div>
      </div>
      {snack ? (
        <Snackbar key={snack.date} status={snack.status} color={snack.color} />
      ) : null}
    </form>
  );
}
