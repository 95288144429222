import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Register from "./../Register";
import Login from "./../Login";
import OTP from "./../OTP";
import EditDetails from "./../EditDetails";
import Profile from "./../Profile";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import ClientDashboard from "../ClientDashboard";
import Detail from "../Detail";
import Image from "../Images/Images";
import Donation from "../../Pages/Donation";

function Index() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={ClientDashboard} />
        <Route exact path="/donation" component={Donation} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/otp/:mobileno" component={OTP} />
        <PrivateRoute exact path="/register" component={Register} />
        <PrivateRoute exact path="/editdetails" component={EditDetails} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <Route path="/:id/images" component={Image} />
        <Route path="/:id" component={Detail} />
      </Switch>
    </BrowserRouter>
  );
}

export default Index;
