import React, { useState, useEffect } from "react";
import Style from "./Style.module.scss";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Lottie from "react-lottie";
import ins from "../../Core/Api";
import staySafe from "./../../Json/staysafe.json";
import { CircularProgress, IconButton } from "@material-ui/core";
import Check from "../../Images/check1.svg";
import DetailImg from "../../Images/detail1.svg";
import Location from "../../Images/location.svg";
import Phone from "../../Images/phone-call.svg";
import Delivery from "../../Images/delivery.svg";
import Calender from "../../Images/uil_calender.svg";
import Whatsapp from "../../Images/whatsapp.svg";
import Logo from "../../Images/Logo.svg";
import servicetype from "../../Images/store.svg";
import Charity from "../../Images/charity.svg";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: staySafe,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Detail() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { id } = useParams();

  console.log("id", id);
  useEffect(() => {
    localStorage.setItem("isback", true);
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = () => {
    var config = {
      method: "get",
      // url: `https://staging.fastor.ai/user/restaurant/${id}`,
      url: `https://api.covidmealsforindia.com/user/restaurant/${id}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log("Res Detail", response.data.status_code);
        if (response.data.status_code === 200) {
          console.log("Res Detail B", response.data);
          setData(response.data.data);
        } else if (response.data.status_code === 404) {
          console.log("Res Detail A");
          history.push("/");
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlewhatsappShare = async (id, mobileno) => {
    const instance = await ins();
    instance
      .patch("/user/restaurant/click", {
        res_code: id,
      })
      .then(async (response) => {
        console.log(response);
        if (response.data.status_code === 200) {
          window.location.href = `http://wa.me/+91${mobileno}?text=Hey%2C+I+found+your+service+on+covidmealsforindia.com+and+I%27m+looking+to+order`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickonNumber = async () => {
    const instance = await ins();
    instance
      .patch("/user/restaurant/click", {
        res_code: id,
      })
      .then(async (response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading)
    return (
      <div className={Style.loadingScreen}>
        <CircularProgress />{" "}
      </div>
    );

  return (
    <div className={Style.container}>
      <div
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ visibility: history.length > 1 ? "visible" : "hidden" }}
          className={Style.bcicon}
        >
          <IconButton>
            <ArrowBackIosIcon
              onClick={() => {
                history.goBack();
              }}
              style={{ alignSelf: "flex-start" }}
            />
          </IconButton>
        </div>

        <div style={{ paddingRight: "10px" }}>
          <img
            onClick={() => history.push("/")}
            src={Logo}
            alt="meals"
            height="80"
            width="60"
          />
        </div>
        <div style={{ visibility: "hidden" }} className={Style.bcicon}>
          <ArrowBackIosIcon style={{ alignSelf: "flex-start" }} />
        </div>
      </div>

      <div className={Style.lgimg}>
        {data?.restaurant_image ? (
          <img src={data?.restaurant_image} alt="restaurant" />
        ) : (
          <Lottie options={defaultOptions} height={200} width={200} />
        )}
      </div>
      <div
        className={Style.circle}
        onClick={() => handlewhatsappShare(id, data?.phone_numbers[0])}
      >
        <img src={Whatsapp} alt="whatsapp" />
      </div>

      <div className={Style.content}>
        <div className={Style.restrauntname}>
          <h1>{data?.restaurant_name}</h1>
          {data?.verification_status === "VERIFIED" && (
            <img src={Check} alt="check" />
          )}
        </div>
        {data?.is_covid_special && (
          <div className={Style.card}>
            <div className={Style.card_img}>
              <img src={DetailImg} alt="detail" />
            </div>
            <div className={Style.card_txt}>
              <h1>Covid food available</h1>
              <p>
                {data?.description
                  ? data.description
                  : "Contact merchant on WhatsApp for further details"}
              </p>
            </div>
          </div>
        )}

        <div className={Style.address}>
          <img src={Location} alt="location" />
          <p>{data?.tmp_location?.address_complete}</p>
        </div>
        {data?.is_charity && (
          <div className={Style.pickup}>
            <img style={{ width: "20px" }} src={Charity} alt="delivery" />
            <p>{"Free food for charity"}</p>
          </div>
        )}
        {data?.service_type && (
          <div className={Style.service_type}>
            <img src={servicetype} alt="location" />
            <p>{data?.service_type}</p>
          </div>
        )}

        <div className={Style.mobile}>
          <img src={Phone} alt="phone" />
          <p onClick={() => handleClickonNumber()}>
            <a
              style={{ color: "#437AF8" }}
              href={`tel:${data?.phone_numbers[0]}`}
            >
              {data?.phone_numbers[0]}
            </a>
            <a
              style={{ color: "#437AF8", paddingLeft: "10px" }}
              href={`tel:${data?.phone_numbers[1]}`}
            >
              {data?.phone_numbers[1]}
            </a>
          </p>
        </div>
        <div className={Style.pickup}>
          <img src={Delivery} alt="delivery" />
          <p>
            {Object?.keys(data?.delivery_mode)
              ?.map((i) => (data?.delivery_mode[i] ? i : null))
              ?.filter((i) => i)
              ?.join(", ")}
          </p>
        </div>

        <div className={Style.time}>
          <img src={Calender} alt="calender" />
          <p>
            {data?.service_days &&
              Object?.keys(data?.service_days)
                ?.slice(0 - 1)
                ?.map((i) => data?.service_days[i]?.open_at)}
          </p>
          <span>&nbsp; -</span>
          <p>
            {data?.service_days &&
              Object?.keys(data?.service_days)
                ?.slice(0 - 1)
                ?.map((i) => data?.service_days[i]?.close_at)}
          </p>
        </div>
        <div className={Style.days}>
          <div id={data?.service_days?.["Sun"] ? Style.selected : ""}>Sun</div>
          <div id={data?.service_days?.["Mon"] ? Style.selected : ""}>Mon</div>
          <div id={data?.service_days?.["Tue"] ? Style.selected : ""}>Tue</div>
          <div id={data?.service_days?.["Wed"] ? Style.selected : ""}>Wed</div>
          <div id={data?.service_days?.["Thu"] ? Style.selected : ""}>Thu</div>
          <div id={data?.service_days?.["Fri"] ? Style.selected : ""}>Fri</div>
          <div id={data?.service_days?.["Sat"] ? Style.selected : ""}>Sat</div>
        </div>
      </div>
      {/* content End */}
      <div className={Style.menuImages}>
        {data?.menu_images?.length > 0 && <h1>Menu Images</h1>}
        <div className={Style.menuImagesContainer}>
          {data?.menu_images?.map((src, key) => (
            <img
              onClick={() => history.push(`/${id}/images`)}
              src={src}
              alt="menu-uploaded"
              style={{
                marginRight: 10,
                width: 95,
                height: 95,
                border: "1px solid #888888",
                borderRadius: 8,
                marginTop: 10,
              }}
            />
          ))}
        </div>
      </div>
      <div className={Style.footer}></div>
    </div>
  );
}
