import React from "react";
import Style from "./Style.module.scss";
import Donation from "../../Images/Donation.svg";

export default function Declaimer() {
  return (
    <div className={Style.desclaimer}>
      <img src={Donation} alt="donation" />
      <p>
        CovidMealsforIndia is enabling verified food NGOs to get donations where
        you can directly donate them on their websites.
      </p>
    </div>
  );
}
