import { createSlice } from "@reduxjs/toolkit";

const dontionSlice = createSlice({
  name: "donationSlice",
  initialState: {
    data: [],
    show: false,
    modalIndex: undefined,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setOpenModal: (state, action) => {
      state.modalIndex = action.payload.index;
      state.show = action.payload.open;
    },
  },
});

export const { setData, setOpenModal } = dontionSlice.actions;
export default dontionSlice.reducer;
