import React, { useEffect } from "react";
import Card from "./Card";
import Declaimer from "./Declaimer";
import DonationModal from "./DonationModal";
import TopBar from "./TopBar";
import Style from "./Style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { FetchDonation } from "../../Actions/Donation";
import { setOpenModal } from "../../Reducers/Donation";
import DonationImage from "./DonationImage";
import Footer from "./Footer";
export default function Donation() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.donation);

  useEffect(() => {
    dispatch(setOpenModal({ open: false, undefined }));
    dispatch(FetchDonation());
  }, [dispatch]);

  return (
    <div className={Style.container}>
      <TopBar />
      <DonationImage />
      <Declaimer />
      {data?.map((data, index) => (
        <Card key={index} index={index} data={data} />
      ))}
      <DonationModal />
      <Footer />
    </div>
  );
}
