const SET_RESTAURANT = "SET_RESTAURANT";
const SET_SEARCHQUERY = "SET_SEARCHQUERY";
const SET_PAGENO = "SET_PAGENO";
const SET_IS_NEXT = "SET_IS_NEXT";
const SET_SELECTED_STATE = "SET_SELECTED_STATE";
const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
const SET_RESET = "SET_RESET";
const SET_APILOCK = "SET_APILOCK";
const SET_CHARITY_ONLY = "SET_CHARITY_ONLY";

const setRestaurant = (data) => {
  return {
    type: SET_RESTAURANT,
    paylod: data,
  };
};
const setSearchQuery = (data) => {
  return {
    type: SET_SEARCHQUERY,
    paylod: data,
  };
};
const setPageNumber = (data) => {
  return {
    type: SET_PAGENO,
    paylod: data,
  };
};
const setIsNext = (data) => {
  return {
    type: SET_IS_NEXT,
    paylod: data,
  };
};
const setSelectedState = (data) => {
  return {
    type: SET_SELECTED_STATE,
    paylod: data,
  };
};
const setTotalCount = (data) => {
  return {
    type: SET_TOTAL_COUNT,
    paylod: data,
  };
};

const setReset = () => {
  console.log("running");
  return {
    type: SET_RESET,
  };
};

const setApiLock = (data) => {
  return {
    type: SET_APILOCK,
    paylod: data,
  };
};
const setCharityOnly = (data) => {
  return {
    type: SET_CHARITY_ONLY,
    paylod: data,
  };
};
export {
  SET_RESTAURANT,
  SET_SELECTED_STATE,
  SET_SEARCHQUERY,
  SET_PAGENO,
  SET_IS_NEXT,
  SET_TOTAL_COUNT,
  SET_RESET,
  SET_APILOCK,
  SET_CHARITY_ONLY,
  setRestaurant,
  setSelectedState,
  setSearchQuery,
  setPageNumber,
  setIsNext,
  setTotalCount,
  setReset,
  setApiLock,
  setCharityOnly,
};
