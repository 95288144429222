import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import RestaurantSlice from "../Reducers/Restaurant";
import Login from "../Reducers/Login";
import ClientReducer from "../Reducers/Client";
import DonationReducer from "../Reducers/Donation";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  restaurant: RestaurantSlice,
  login: Login,
  client: ClientReducer,
  donation: DonationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
