import React from "react";
import Style from "./Style.module.scss";
import Location from "../../Images/location.svg";
import Phone from "../../Images/phone-call.svg";
import Dot from "../../Images/Dot.svg";
import { useDispatch } from "react-redux";
import { setOpenModal } from "../../Reducers/Donation";
import { CardActionArea } from "@material-ui/core";

export default function Card({ data, index }) {
  const dispatch = useDispatch();
  return (
    <CardActionArea
      className={Style.card}
      onClick={() => dispatch(setOpenModal({ open: true, index }))}
    >
      <hr />
      <div className={Style.cardData}>
        <div className={Style.cardData_img}>
          <img src={data?.img} alt="logo" />{" "}
        </div>
        <div className={Style.cardData_data}>
          <h1>{data.name}</h1>
          {data.address && (
            <div>
              <img src={Location} alt="location " />
              <p> {data?.address}</p>
            </div>
          )}

          {data?.phone_numbers?.length > 0 && (
            <div>
              <img src={Phone} alt="location " />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#434343",
                }}
              >
                {data.phone_numbers.map((num) => num).join(", ")}
              </p>
            </div>
          )}

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <img src={Dot} alt="des" />
            </div>
            <div className={Style.descriptiontext}>
              <p>{data?.description}</p>
            </div>
          </div>
        </div>
      </div>
    </CardActionArea>
  );
}
