import React, { useEffect, useState } from "react";
import Style from "./Style.module.scss";
import { useHistory } from "react-router-dom";
import { CardActionArea } from "@material-ui/core";
import Location from "../../Images/location.svg";
import Phone from "../../Images/phone-call.svg";
import Delivery from "../../Images/delivery.svg";
import Calender from "../../Images/uil_calender.svg";
import Check from "../../Images/check1.svg";
import Whatsapp from "../../Images/whatsapp.svg";
import DetailImg from "../../Images/detail1.svg";
import servicetype from "../../Images/store.svg";
import Charity from "../../Images/charity.svg";
export default function Card({
  data,
  searchQuery,
  selectedState,
  index,
  scrollHeight,
}) {
  const history = useHistory();
  const [modes, setModes] = useState([]);

  useEffect(() => {
    const tmodes = [];
    for (const property in data?.delivery_mode) {
      if (data?.delivery_mode[property] === true) {
        tmodes.push(property);
      }
    }
    setModes(tmodes);
  }, [data?.delivery_mode]);

  return (
    <CardActionArea
      onClick={() => {
        history.push(`/${data.res_code}`);
      }}
    >
      <div className={Style.container} style={{ position: "relative" }}>
        <div className={Style.heading}>
          {data.restaurant_image && (
            <img src={data.restaurant_image} alt="restaurant" />
          )}
          <div className={Style.heading_title}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <h1 style={{ paddingRight: "10px" }}>{data.restaurant_name}</h1>
              {data?.verification_status === "VERIFIED" && (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={Check}
                  alt="check"
                />
              )}
            </div>
            <div style={{ maxWidth: "100%" }}>
              <img src={Location} alt="location" />
              <p style={{ maxWidth: "80%" }}>
                {data?.tmp_location?.address_complete}
              </p>
            </div>
          </div>
        </div>
        {data?.is_charity && (
          <div className={Style.service_type}>
            <img style={{ width: "20px" }} src={Charity} alt="delivery" />
            <p>{"Free Food for Charity"}</p>
          </div>
        )}
        {data?.service_type && (
          <div className={Style.service_type}>
            <img src={servicetype} alt="servicetype" />
            <p>{data?.service_type}</p>
          </div>
        )}
        <div className={Style.mobile}>
          <img src={Phone} alt="phone" />
          <p>
            {data.phone_numbers[0]}
            {data.phone_numbers[1] && ", " + data.phone_numbers[1]}
          </p>
        </div>
        {modes.length > 0 && (
          <div className={Style.pickup}>
            <img src={Delivery} alt="delivery" />
            <p>{modes.join(", ")}</p>
          </div>
        )}

        <div className={Style.time}>
          <img src={Calender} alt="calender" />
          <p>
            {data?.service_days &&
              Object?.keys(data?.service_days)
                ?.slice(0 - 1)
                ?.map((i) => data?.service_days[i]?.open_at)}
          </p>
          <span>&nbsp; - </span>
          <p>
            {data?.service_days &&
              Object?.keys(data?.service_days)
                ?.slice(0 - 1)
                ?.map((i) => data?.service_days[i]?.close_at)}
          </p>
        </div>

        <div
          className={Style.days}
          style={{ marginBottom: data.is_covid_special ? 0 : 20 }}
        >
          <div id={data.service_days?.["Sun"] ? Style.selected : ""}>Sun</div>
          <div id={data.service_days?.["Mon"] ? Style.selected : ""}>Mon</div>
          <div id={data.service_days?.["Tue"] ? Style.selected : ""}>Tue</div>
          <div id={data.service_days?.["Wed"] ? Style.selected : ""}>Wed</div>
          <div id={data.service_days?.["Thu"] ? Style.selected : ""}>Thu</div>
          <div id={data.service_days?.["Fri"] ? Style.selected : ""}>Fri</div>
          <div id={data.service_days?.["Sat"] ? Style.selected : ""}>Sat</div>
        </div>

        {data.is_covid_special && (
          <div className={Style.covidfood}>
            <img src={DetailImg} alt="check" />
            <p>Covid food available</p>
          </div>
        )}
        <img
          src={Whatsapp}
          alt="whatsapp"
          style={{ position: "absolute", bottom: 0, right: 10 }}
          // onClick={() => handleShare(data.res_code, data?.phone_numbers[0])}
        />
      </div>
    </CardActionArea>
  );
}
